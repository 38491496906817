<template>

    <div class="mainContainer">
        <div style="font-size: 14px; font-weight: 600; margin-bottom: 10px;">{{$t("Customer.PackageAppointments.patient_info")}}</div>
        <div style="width: 100%; display: flex; flex-direction: column; justify-content: flex-start; gap: 20px; border: 1px solid #e0e0e0; border-radius: 8px; padding: 16px;">
            <div class="infoBox" style="border-bottom: 1px solid #e0e0e0; ">
                <div class="nameBox"> {{$t("Customer.OpdAppointments.Name")}}</div>
                <div class="valueBox"> {{ bookingInfo.patientInfo.name }}</div>
            </div>    
            <div class="infoBox" style="border-bottom: 1px solid #e0e0e0; ">
                <div class="nameBox"> {{$t("Customer.MyFamily.Age")}}</div>
                <div class="valueBox"> {{ bookingInfo.patientInfo.age }}</div>
            </div>    
            <div class="infoBox">
                <div class="nameBox"> {{$t("Customer.MyFamily.Gender")}}</div>
                <div class="valueBox"> {{ bookingInfo.patientInfo.gender }}</div>
            </div>    
        </div>
    </div>
    </template>
    
    <script>
    
    
    
    export default {
        name: 'PatiendInfoComponent',
        props: ["bookingInfo"],
        data: function() {
            return {
                todayAppointment: [],
                pageLoading: true,
                ongoingAppointment: [],
                bannerURL: '',
                viewDetailsModel: false,
                hospitalName: 'Asia Royal Hospital',
                hospitalLocation: 'Yangon',
                hospitalRegNumber: '1241424',
                phoneNumber: '+95 67980342453',
                reviews: '299',
                doctorCount: 56,
                fullDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint.',
                readMore: false,
            };
        },
        computed: {
            textPreview() {
                return this.fullDescription.slice(0, 100) + '...';
            }
        },
        async mounted() {
        },
        methods: {
            goToOPDPage() {
                this.$router.push({
                    name: 'OPDHome'
                })
            },
            toggleHospitalDetails() {
                this.viewDetailsModel = !this.viewDetailsModel;
            },
            closeBottomSheet() {
                this.viewDetailsModel = false;
            },
            descriptionPreview() {
    
            }
        },
    };
    
    </script>
    <style  scoped>
    .mainContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        margin-top: 25px;
    }
    .doctorName {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }
    .doctorProfile {
        border-radius: 50%;
        max-height: 56px;
        max-width: 56px;
        height: 56px;
        width: 56px;
        border: 1px solid #e0e0e0;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .doctorNameAndLogo {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-grow: 1;
    }
    .specialtyBox {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E4F5FF;
        padding: 3px 8px 3px 8px;
    }
    .hospitalName {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    .hospitalNameAndLogo {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        font-size: 14px !important;
    }
    .infoBox {
        display: flex;
        flex-direction: column;
        /* border-bottom: 1px solid #e0e0e0; */
        padding: 0 0 8px 0;
        gap: 10px;
        align-items: flex-start;
    }
    .nameBox {
        font-size: 14px;
        color: #828282;
        font-weight: 400;
    }
    .valueBox {
        font-size: 14px;
        color: #333333;
        font-weight: 500;   
    }
    </style>
        